import sitesReducer from './reducers/sites.js';
import youthReducer from './reducers/youth.js';
import cycleReducer from './reducers/cycle.js';
import dataReducer from './reducers/data.js';
import messageReducer from './reducers/message.js';
import reportReducer from './reducers/report.js';

export default {
    sites: {
        reducer: sitesReducer,
    },
    youth: {
        reducer: youthReducer,
    },
    cycle: {
        reducer: cycleReducer,
    },
    data: {
        reducer: dataReducer,
    },
    messageReducer: {
        reducer: messageReducer,
    },
    report: {
        reducer: reportReducer,
    },
};
