import { handleActions, createAction } from 'redux-actions';

// This reducer handles storing filter settings for reports
//    Some filters are dependant on data from other filters
//

const now = new Date();

export const initialState = {
    sites: [],
    careCoords: [],
    dateFrom: now,
    dateTo: now,
    respondents: [],
    teamMemberTypes: [],
    cycles: [],
    youthPawnIds: [],
    widgetsLoaded: [],
    ccoYouthMap: null,
};

// Actions
const SET_SITES = 'app/report/SET_SITES';
const SET_RESPONDENTS = 'app/report/SET_RESPONDENTS';
const SET_TEAM_MEMBER_TYPES = 'app/report/SET_TEAM_MEMBER_TYPES';
const SET_DATE_TO = 'app/report/SET_DATE_TO';
const SET_DATE_FROM = 'app/report/SET_DATE_FROM';
const SET_CYCLES = 'app/report/SET_CYCLES';
const SET_CARE_COORDS = 'app/report/SET_CARE_COORDS';
const SET_YOUTH_PAWN_IDS = 'app/report/SET_YOUTH_PAWN_IDS';
const SET_WIDGET_LOADED = 'app/report/SET_WIDGET_LOADED';
const RESET_WIDGETS_LOADED = 'app/report/RESET_WIDGETS_LOADED';
const SET_CCO_YOUTH_MAP = 'app/report/SET_CCO_YOUTH_MAP';

// Action Creators
export const setSites = createAction(SET_SITES);
export const setRespondents = createAction(SET_RESPONDENTS);
export const setTeamMemberTypes = createAction(SET_TEAM_MEMBER_TYPES);
export const setDateTo = createAction(SET_DATE_TO);
export const setDateFrom = createAction(SET_DATE_FROM);
export const setCycles = createAction(SET_CYCLES);
export const setCareCoords = createAction(SET_CARE_COORDS);
export const setYouthPawnIds = createAction(SET_YOUTH_PAWN_IDS);
export const setWidgetLoaded = createAction(SET_WIDGET_LOADED);
export const resetWidgetsLoaded = createAction(RESET_WIDGETS_LOADED);
export const setCcoYouthMap = createAction(SET_CCO_YOUTH_MAP);
// Reducers
export default handleActions(
    {
        [SET_SITES]: (state, action) => ({
            ...state,
            sites: action.payload,
        }),

        [SET_RESPONDENTS]: (state, action) => ({
            ...state,
            respondents: action.payload,
        }),

        [SET_TEAM_MEMBER_TYPES]: (state, action) => ({
            ...state,
            teamMemberTypes: action.payload,
        }),
        [SET_DATE_FROM]: (state, action) => ({
            ...state,
            dateFrom: action.payload,
        }),
        [SET_DATE_TO]: (state, action) => ({
            ...state,
            dateTo: action.payload,
        }),
        [SET_CYCLES]: (state, action) => ({
            ...state,
            cycles: action.payload,
        }),
        [SET_CARE_COORDS]: (state, action) => ({
            ...state,
            careCoords: action.payload,
        }),
        [SET_CCO_YOUTH_MAP]: (state, action) => ({
            ...state,
            ccoYouthMap: action.payload,
        }),
        [SET_YOUTH_PAWN_IDS]: (state, action) => ({
            ...state,
            youthPawnIds: action.payload,
        }),
        [SET_WIDGET_LOADED]: (state, action) => {
            if (!state.widgetsLoaded.includes(action.payload)) {
                return { ...state, widgetsLoaded: [action.payload, ...state.widgetsLoaded] };
            }
            return state;
        },
        [RESET_WIDGETS_LOADED]: (state, action) => ({
            ...state,
            widgetsLoaded: [],
        }),
    },
    initialState
);
