import { handleActions, createAction } from 'redux-actions';

// This reducer handles storing the user's selections on data management

export const initialState = {
    site: null,
    cycles: {
        careCoord: null,
        caregiver: null,
        teamMember: null,
        youth: null,
        tom: null,
        dart: null,
    },
    tabs: [],
};

// Actions
const SET_SITE = 'app/cycle/SET_SITE';
const SET_CYCLE = 'app/cycle/SET_CYCLE';
const SET_TABS = 'app/cycle/SET_TABS';

// Action Creators
export const setSite = createAction(SET_SITE);
export const setCycle = createAction(SET_CYCLE);
export const setTabs = createAction(SET_TABS);

// Reducers
export default handleActions(
    {
        [SET_SITE]: (state, action) => ({
            ...state,
            site: action.payload,
        }),
        [SET_CYCLE]: (state, action) => ({
            ...state,
            cycles: { ...state.cycles, [action.payload.name]: action.payload.id },
        }),
        [SET_TABS]: (state, action) => ({
            ...state,
            tabs: [...state.tabs.filter(tab => tab.tabId !== action.payload.tabId), action.payload],
        }),
    },
    initialState
);
