import { handleActions, createAction } from 'redux-actions';

// This reducer handles storing filter settings for the YouthRoster table

export const initialState = {
    youth: [],
    eligibleYouth: [],
    cyclePreviewData: [], // site, sampleSize info for preview table
    sample: [], // for custom cycle, stores the pawnIds to be added
};

// Actions
const SET_YOUTH = 'app/cycle/SET_YOUTH';
const SET_ELIGIBLE_YOUTH = 'app/cycle/SET_ELIGIBLE_YOUTH';
const SET_CYCLE_PREVIEW_DATA = 'app/cycle/SET_CYCLE_PREVIEW_DATA';
const SET_SAMPLE = 'app/cycle/SET_SAMPLE';

// Action Creators
export const setYouth = createAction(SET_YOUTH);
export const setElegibleYouth = createAction(SET_ELIGIBLE_YOUTH);
export const setCyclePreviewData = createAction(SET_CYCLE_PREVIEW_DATA);
export const setSample = createAction(SET_SAMPLE);

// Reducers
export default handleActions(
    {
        [SET_YOUTH]: (state, action) => ({
            ...state,
            youth: action.payload,
        }),
        [SET_ELIGIBLE_YOUTH]: (state, action) => ({
            ...state,
            eligibleYouth: action.payload,
        }),
        [SET_CYCLE_PREVIEW_DATA]: (state, action) => ({
            ...state,
            cyclePreviewData: action.payload,
        }),
        [SET_SAMPLE]: (state, action) => ({
            ...state,
            sample: action.payload,
        }),
    },
    initialState
);
