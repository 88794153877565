import { handleActions, createAction } from 'redux-actions';

// This reducer handles storing filter settings for the YouthRoster table

export const initialState = {
    youthStatus: 'Active',
    showAllDischarged: false,
    formPage: 1,
};

// Actions
const SET_STATUS = 'app/youth/SET_STATUS';
const TOGGLE_SHOW_ALL_DISCHARGED = 'app/youth/SET_SHOW_ALL_DISCHARGED';
const SET_FORM_PAGE = 'app/youth/SET_FORM_PAGE';
const NEXT_FORM_PAGE = 'app/youth/NEXT_FORM_PAGE';
const PREV_FORM_PAGE = 'app/youth/PREV_FORM_PAGE';

// Action Creators
export const setStatus = createAction(SET_STATUS);
export const toggleShowAllDischarged = createAction(TOGGLE_SHOW_ALL_DISCHARGED);
export const setFormPage = createAction(SET_FORM_PAGE);
export const nextFormPage = createAction(NEXT_FORM_PAGE);
export const prevFormPage = createAction(PREV_FORM_PAGE);

// Reducers
export default handleActions(
    {
        [SET_STATUS]: (state, action) => ({
            ...state,
            youthStatus: action.payload,
        }),
        [TOGGLE_SHOW_ALL_DISCHARGED]: (state, action) => ({
            ...state,
            showAllDischarged: !state.showAllDischarged,
        }),
        [SET_FORM_PAGE]: (state, action) => ({
            ...state,
            formPage: action.payload,
        }),
        [NEXT_FORM_PAGE]: (state, action) => ({
            ...state,
            formPage: state.formPage + 1,
        }),
        [PREV_FORM_PAGE]: (state, action) => ({
            ...state,
            formPage: state.formPage - 1,
        }),
    },
    initialState
);
