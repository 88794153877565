import { handleActions, createAction } from 'redux-actions';
import { client as apollo } from 'cccisd-apollo';
import designTabQuery from '../graphql/sendNow.graphql';
import projectListQuery from '../graphql/projectListQuery.graphql';
import { getCurrentProjectAssignments } from '../selectors/message.js';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

// This reducer handles the send now messaging component

export const initialState = {
    projectList: [],
    currentProjectId: 0,
    currentAssignmentId: 0,
    currentSurveyListHandles: {},
};

// Actions
const SET_PROJECT_LIST = 'assignment/admin/SET_PROJECT_LIST';
const SET_PROJECT_FLOWS = 'assignment/admin/SET_PROJECT_FLOWS';
const SET_CURRENT_PROJECT_ID = 'assignment/admin/SET_CURRENT_PROJECT_ID';

// Action Creators
export const setProjectList = createAction(SET_PROJECT_LIST);
export const setProjectFlows = createAction(SET_PROJECT_FLOWS);
export const setCurrentProjectId = createAction(SET_CURRENT_PROJECT_ID);

// helpers

export const loadProjectList = () => {
    return async (dispatch, getState) => {
        // Don't load projects twice
        const state = getState().assignment.admin;
        if (state.projectList.length > 0) {
            return;
        }

        if (Fortress.user.acting.data_type === 'guest') {
            window.location = Boilerplate.url('account/login');
            return;
        }

        let result = null;
        try {
            result = await apollo.query({
                query: projectListQuery,
                fetchPolicy: 'network-only',
            });
        } catch (e) {
            return;
        }

        const projectList = result.data.groups.questprojectList.map(project => ({
            ...project.group,
            fields: project.fields,
        }));
        dispatch(setProjectList(projectList));
    };
};

export const loadProject = groupId => {
    return async (dispatch, getState) => {
        if (Fortress.user.acting.data_type === 'guest') {
            window.location = Boilerplate.url('account/login');
            return;
        }

        let result = null;
        try {
            result = await apollo.query({
                query: designTabQuery,
                variables: { groupId },
                fetchPolicy: 'network-only',
            });
        } catch (e) {
            return;
        }

        dispatch(setProjectFlows({ groupId, flows: result.data.flows }));
        dispatch(setCurrentProjectId(groupId));
    };
};

export const reloadCurrentProject = () => {
    return async (dispatch, getState) => {
        const { currentProjectId } = getState().assignment.admin;
        await dispatch(loadProject(currentProjectId));
    };
};

// Reducers
export default handleActions(
    {
        [SET_PROJECT_LIST]: (state, action) => ({
            ...state,
            projectList: action.payload,
        }),
        [SET_CURRENT_PROJECT_ID]: (state, action) => {
            const nextState = {
                ...state,
                currentProjectId: action.payload,
            };

            const { currentAssignmentId } = state;
            const activeAssignmentsIds = getCurrentProjectAssignments(nextState)
                .filter(item => !item.archived)
                .map(item => item.assignmentId);

            if (activeAssignmentsIds.includes(currentAssignmentId)) {
                return nextState;
            }

            return {
                ...nextState,
                currentAssignmentId: activeAssignmentsIds[0] || 0,
            };
        },
        [SET_PROJECT_FLOWS]: (state, action) => ({
            ...state,
            projectList: state.projectList.map(project =>
                project.groupId === action.payload.groupId
                    ? { ...project, flows: action.payload.flows }
                    : project
            ),
        }),
    },

    initialState
);
